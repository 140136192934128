import React from 'react';
import { CardProps } from './CardProps';
import { Block, BlockAlign, BlockFlow, BlockProps } from '../Block';
import { st, classes } from './CardWrapper.st.css';
import { StyledDivider } from '../Divider/Divider';

interface CardWrapperProps extends CardProps, BlockProps {
  action?: React.ReactNode;
  align?: BlockAlign;
}

export const CardWrapper: React.FunctionComponent<CardWrapperProps> = (
  props,
) => {
  const { action, divider, children, align, flow, ...rest } = props;
  return (
    <Block
      flow={BlockFlow.row}
      className={st(classes.root, {}, rest.className)}
    >
      <Block
        align={align || BlockAlign.center}
        end={action}
        className={classes.cardBlock}
        flow={flow}
      >
        {children}
      </Block>
      {divider && <StyledDivider className={classes.divider} />}
    </Block>
  );
};
CardWrapper.displayName = 'CardWrapper';
