import React from 'react';
import { st, classes } from './Placeholder.st.css';

interface PlaceholderCardProps {
  children?: React.ReactNode;
}

interface PlaceholderTitleProps extends PlaceholderCardProps {
  className?: string;
}

export const PlaceholderTitle: React.FunctionComponent<PlaceholderTitleProps> = ({
  children,
  ...rest
}: PlaceholderTitleProps) => {
  return (
    <div className={st(classes.root, { empty: !children }, rest.className)}>
      <div className={classes.wrapper}>
        <span className={classes.placeholder}>{children}</span>
      </div>
    </div>
  );
};
PlaceholderTitle.displayName = 'PlaceholderTitle';

export const PlaceholderAvatar: React.FunctionComponent<PlaceholderCardProps> = ({
  children,
}: PlaceholderCardProps) => {
  return <div className={st(classes.root, {})}>{children}</div>;
};
PlaceholderAvatar.displayName = 'PlaceholderAvatar';
