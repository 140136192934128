import React from 'react';
import { ButtonProps, PRIORITY } from 'wix-ui-tpa/Button';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Button } from '../../../../common/components/Button';
import { useUser } from '../../../../common/context/user/useUser';
import { useMemberFollow } from 'Group/contexts/MemberFollow/useMemberFollow';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import { useGroupBI } from 'Group/Widget/hooks/useGroupBI';

interface FollowButtonComponentProps extends ButtonProps {
  siteMemberId: string;
  textButton?: boolean;
}

const FollowButtonComponent: React.FC<FollowButtonComponentProps> = (props) => {
  const { siteMemberId, textButton, ...rest } = props;
  const { t } = useTranslation();
  const { myMember, userActions } = useUser();
  const { following, followActions } = useMemberFollow();
  const bi = useGroupBI();
  const isFollowing = following?.memberIds?.includes(siteMemberId);
  const isCurrentUser = myMember?.id === siteMemberId;

  const onClick = (e: any) => {
    e.stopPropagation();

    if (isCurrentUser) {
      userActions.openUserProfile(siteMemberId);
      return;
    }

    if (isFollowing) {
      followActions?.unfollowMember({ memberId: siteMemberId });
      bi.followMember({ site_member_id: siteMemberId, action: 'unfollow' });
      return;
    }

    followActions?.followMember({ memberId: siteMemberId });
    bi.followMember({ site_member_id: siteMemberId, action: 'follow' });
  };

  const followingLabel = isFollowing
    ? t('groups-web.following')
    : t('groups-web.follow');
  if (textButton) {
    return (
      <TextButton
        {...rest}
        onClick={onClick}
        priority={TEXT_BUTTON_PRIORITY.primary}
      >
        {followingLabel}
      </TextButton>
    );
  }
  return (
    <Button
      priority={
        isCurrentUser || isFollowing ? PRIORITY.secondary : PRIORITY.primary
      }
      onClick={onClick}
    >
      {isCurrentUser ? t('groups-web.my-profile') : followingLabel}
    </Button>
  );
};

export const FollowButton = FollowButtonComponent;
FollowButton.displayName = 'FollowButton';
