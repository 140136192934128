import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import debounce from 'lodash/debounce';
import { LabelPlacement } from 'wix-ui-tpa/IconToggle';

import { ThumbUpIcon } from 'common/components/Icons/ThumbUpIcon';
import { IReactionsSummary } from 'FeedWidget/api/types';
import { IStore } from 'FeedWidget/store/types';

import { getLikeSummary, getReactionsSummaryMap } from './selectors';
import { Reaction } from './Reaction';
import { EmojiPicker } from './EmojiPicker';

import { st, classes } from './Reactions.st.css';
import { useSelector } from 'react-redux';

interface IReactionsProps {
  reactions: IReactionsSummary;
  className?: string;

  react(code: string): void;

  unreact(code: string): void;
}

export function Reactions(props: IReactionsProps) {
  const handleReact = debounce(react, 300, {
    leading: true,
    trailing: false,
  });

  const currentUserId = useSelector(
    (state: IStore) => state.application.user.id,
  );

  const usersReactionsMap = getReactionsSummaryMap(
    props.reactions.usersReactions.reactions!,
  );
  const likeSummary = getLikeSummary(usersReactionsMap, '👍');
  const liked = isAlreadyReacted(likeSummary.reactionCode);

  const usersReactions = Object.values(usersReactionsMap).filter(
    (usersReaction) => usersReaction.reactionCode !== likeSummary.reactionCode,
  );

  return (
    <div className={st(classes.root, {}, props.className)}>
      <ul className={classes.list}>
        <TransitionGroup>
          <CSSTransition
            timeout={300}
            classNames={classes as CSSTransitionClassNames}
          >
            <li className={classes.item}>
              <Reaction
                likeButton
                onClick={() => handleReact(likeSummary.reactionCode)}
                checked={liked}
                labelPlacement={LabelPlacement.END}
                icon={!liked ? <ThumbUpIcon /> : likeSummary.reactionCode}
                label={likeSummary.total.toString()}
                reactedMembers={likeSummary.users}
              />
            </li>
          </CSSTransition>
          {usersReactions.map(({ reactionCode, total, users }) => (
            <CSSTransition
              timeout={300}
              classNames={classes as CSSTransitionClassNames}
              key={reactionCode}
            >
              <li className={classes.item}>
                <Reaction
                  className="emoji-mart-emoji-native"
                  onClick={() => handleReact(reactionCode)}
                  checked={isAlreadyReacted(reactionCode)}
                  labelPlacement={LabelPlacement.END}
                  icon={reactionCode}
                  label={total}
                  reactedMembers={users}
                />
              </li>
            </CSSTransition>
          ))}
          <li className={classes.item}>
            <EmojiPicker onSelect={handleReact} />
          </li>
        </TransitionGroup>
      </ul>
    </div>
  );

  function react(code: string) {
    isAlreadyReacted(code) ? props.unreact(code) : props.react(code);
  }

  function isAlreadyReacted(code: string) {
    const { [code]: reaction } = usersReactionsMap;

    return !!reaction?.users.find(
      (user) => user.siteMemberId === currentUserId,
    );
  }
}

Reactions.displayName = 'Reactions';
