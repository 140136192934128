import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

import { stylesParams as groupStylesParams } from 'Group/Settings/styles';

export type IStylesParams = {
  groupTitleColor: StyleParamType.Color;
  groupTitleFont: StyleParamType.Font;

  groupSubtitleColor: StyleParamType.Color;
  groupSubtitleFont: StyleParamType.Font;

  postsTextColor: StyleParamType.Color;
  postsTextFont: StyleParamType.Font;

  memberNameColor: StyleParamType.Color;
  memberNameFont: StyleParamType.Font;

  postingDateColor: StyleParamType.Color;
  postingDateFont: StyleParamType.Font;

  commentsReactionsCounterColor: StyleParamType.Color;
  commentsReactionsCounterFont: StyleParamType.Font;

  postsBackgroundColor: StyleParamType.Color;
  postsBorderColor: StyleParamType.Color;
  commentsBoxBorderColor: StyleParamType.Color;
  postsBorderWidth: StyleParamType.Number;
  commentsBoxBorderWidth: StyleParamType.Number;
  commentsApplicationBackground: StyleParamType.Color;
  commentsUserNameFont: StyleParamType.Font;
  commentsCommentContentFont: StyleParamType.Font;
  commentsReplyFont: StyleParamType.Font;
  commentsUserNameColor: StyleParamType.Color;
  commentsCommentContentColor: StyleParamType.Color;
  commentsReplyColor: StyleParamType.Color;
  commentsShowMoreCommentsLink: StyleParamType.Color;
  commentsShowMoreRepliesLink: StyleParamType.Color;
  commentsTimeStampFont: StyleParamType.Font;
  commentsTimeStampColor: StyleParamType.Color;

  layoutSpacing: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  groupTitleColor: groupStylesParams.groupTitleColor,
  groupTitleFont: groupStylesParams.groupTitleFont,

  groupSubtitleColor: groupStylesParams.groupSubtitleColor,
  groupSubtitleFont: groupStylesParams.groupSubtitleFont,

  postsTextColor: groupStylesParams.postsTextColor,
  postsTextFont: groupStylesParams.postsTextFont,

  memberNameColor: groupStylesParams.memberNameColor,
  memberNameFont: groupStylesParams.memberNameFont,

  postingDateColor: groupStylesParams.postingDateColor,
  postingDateFont: groupStylesParams.postingDateFont,

  commentsReactionsCounterColor:
    groupStylesParams.commentsReactionsCounterColor,
  commentsReactionsCounterFont: groupStylesParams.commentsReactionsCounterFont,

  commentsApplicationBackground:
    groupStylesParams.commentsApplicationBackground,
  postsBackgroundColor: groupStylesParams.postsBackgroundColor,
  postsBorderColor: groupStylesParams.postsBorderColor,
  commentsBoxBorderColor: groupStylesParams.commentsBoxBorderColor,

  postsBorderWidth: groupStylesParams.postsBorderWidth,
  commentsBoxBorderWidth: groupStylesParams.commentsBoxBorderWidth,

  commentsUserNameFont: groupStylesParams.commentsUserNameFont,
  commentsUserNameColor: groupStylesParams.commentsUserNameColor,

  commentsReplyFont: groupStylesParams.commentsReplyFont,

  commentsCommentContentFont: groupStylesParams.commentsCommentContentFont,
  commentsCommentContentColor: groupStylesParams.commentsCommentContentColor,
  commentsReplyColor: groupStylesParams.commentsReplyColor,
  commentsShowMoreCommentsLink: groupStylesParams.commentsShowMoreCommentsLink,
  commentsShowMoreRepliesLink: groupStylesParams.commentsShowMoreRepliesLink,
  commentsTimeStampFont: groupStylesParams.commentsTimeStampFont,
  commentsTimeStampColor: groupStylesParams.commentsTimeStampColor,

  layoutSpacing: groupStylesParams.layoutSpacing,
});
