import React from 'react';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { SiteMembersContext } from '../../../contexts/SiteMembers/SiteMembers';
import { useUser } from 'common/context/user/useUser';
// TODO: [YO] fix types
export function useMentions() {
  const [query, setQuery] = React.useState<string>();
  const [resolve, setResolve] = React.useState<Function>();

  const { group } = React.useContext(GroupContext);
  const { membersActions, membersQueryResponse } = React.useContext(
    SiteMembersContext,
  );
  const { userActions } = useUser();
  React.useEffect(() => {
    if (!resolve) {
      return;
    }

    if (query === membersQueryResponse.query) {
      resolve(
        membersQueryResponse.members.map((member) => ({
          name: member.name!.nick,
          avatar: member.imageUrl,
          id: member.siteMemberId,
        })),
      );
    }
  }, [membersQueryResponse.query, query, resolve]);

  return {
    getMentions,
    onMentionClick,
  };

  function onMentionClick(mention: any) {
    userActions.openUserProfile(mention.id);
  }

  function getMentions(_query: any) {
    return new Promise((_resolve) => {
      setResolve(() => _resolve);
      setQuery(_query);
      membersActions.filterMembers(group, _query);
    });
  }
}
