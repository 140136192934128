import React from 'react';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Checkbox } from 'wix-ui-tpa/Checkbox';
import { CardProps } from './CardProps';
import { CardText, CardTextProps } from './CardText';
import { CardWrapper } from './CardWrapper';
import { classes, st } from './UserCard.st.css';
import * as avatarStyles from './Placeholders/PlaceholderAvatar.st.css';
import { Block, BlockFlow } from '../Block';
import { isObject } from '../../utils/utils';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Theme } from '../../context/theme';

interface UserCardProps extends CardProps, CardTextProps, Theme {
  name: React.ReactNode;
  pic: string | React.ReactNode;
  userAction?: React.ReactNode;
  avatarSize?: AvatarSize;

  allowSelect?: boolean;
  selected?: boolean;
  loading?: boolean;

  onSelect?(selected: boolean): void;

  onUserClick?(): void;
  mobile?: boolean;
}

export const EMPTY_SVG =
  'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>';
// TODO: driver
const UserCardComponent: React.FunctionComponent<
  UserCardProps & WithTranslation
> = (props) => {
  const {
    pic,
    userAction,
    divider,
    name,
    dataHook,
    subtitle,
    avatarSize,
    reverse,
    onUserClick,
    loading,
    allowSelect,
    onSelect,
    selected,
    t,
    mobile,
    forceBlackAndWhite,
    ...rest
  } = props;

  const renderAvatar = () => {
    return isObject(pic) ? (
      <div>{pic}</div>
    ) : (
      <Avatar
        src={loading ? EMPTY_SVG : (pic as string)}
        size={avatarSize}
        className={avatarStyles.st(avatarStyles.classes.root, {
          loading,
        } as any)}
      />
    );
  };

  return (
    <CardWrapper
      action={!loading && userAction}
      divider={divider}
      autoContent
      className={st(
        classes.root,
        {
          withHover: !!onUserClick,
          allowSelect,
          bw: !!forceBlackAndWhite,
        } as any,
        rest.className,
      )}
      flow={mobile ? BlockFlow.row : BlockFlow.col}
    >
      <Block onClick={onUserClick} className={classes.userBlock}>
        {allowSelect ? (
          <Checkbox
            className={classes.selectCheckbox}
            checked={selected}
            onChange={(evt) => {
              if (onSelect) {
                onSelect(evt.checked);
              }
            }}
          />
        ) : null}
        {renderAvatar()}
        <div>
          <CardText
            forceBlackAndWhite={forceBlackAndWhite}
            subtitle={subtitle}
            title={name}
            dataHook={dataHook}
            reverse={reverse}
            ellipsis
            withPlaceholder={loading}
          />
          {props.children}
        </div>
      </Block>
    </CardWrapper>
  );
};

export const UserCard = withTranslation()(
  UserCardComponent,
) as React.ComponentType<UserCardProps>;

UserCard.displayName = 'UserCard';
