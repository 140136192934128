import React from 'react';

import { st, classes } from './Box.st.css';

export interface BoxProps {
  article?: boolean;
  withTopBottomBorders?: boolean;
  withSideBorders?: boolean;
}

/**
 * Box
 */
export const Box: React.FunctionComponent<
  BoxProps & JSX.IntrinsicElements['article']
> = ({
  article,
  children,
  withSideBorders,
  withTopBottomBorders,
  ...props
}) => {
  return (
    <article
      {...props}
      className={st(
        classes.root,
        { article, withSideBorders, withTopBottomBorders } as any,
        props.className,
      )}
    >
      {children}
    </article>
  );
};

Box.defaultProps = {
  withSideBorders: true,
  withTopBottomBorders: true,
};
Box.displayName = 'Box';
